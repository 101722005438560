import axios from "axios";
import React, { useEffect, useState } from "react";
import { Card, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import { useGlobalContext } from "global/context";

const ComplianceGrid = () => {
  const navigate = useNavigate();
  let { userData } = useGlobalContext();
  const [allcompliance, setAllCompliance] = useState([]);
  const [complianceId, setCompliacneId] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const [attempts, setAttempts] = useState([]);
  const [answer, setAnswer] = useState({});
  const [allCategoryOfCompliance, setAllCategoryOfCompliance] = useState([]);
  const [compliance, setComplinace] = useState({});
  const [question, setQuestion] = useState([]);

  const getAttempts = () => {
    if (userData.id) {
      axios.get(process.env.REACT_APP_API_BASE_URL + "admin/compliance_answer/institute/" + userData.id).then((response) => {
        // console.log('inside getAttempts', response.data.result);
        setAttempts(response.data.result);
        // getAnswer();
      });
    }
  };
  const checkAttempts = (id) => {
    const check = attempts.find((i) => i.complianceId === id);
    if (check) {
      return true;
    }
    return false;
  };

  const getAllCategoryOfCompliance = (id) => {
    // console.log("this is id", id);
    axios.get(process.env.REACT_APP_API_BASE_URL + "admin/compliance_category/compliance/" + id).then((res) => {
      setAllCategoryOfCompliance(res.data.result);
    });
  };
  const getCompliance = (id) => {
    axios.get(process.env.REACT_APP_API_BASE_URL + "admin/compliance/" + id).then((res) => {
      setComplinace(res.data.result);
    });
  };
  const getQuestion = (id) => {
    axios.get(process.env.REACT_APP_API_BASE_URL + "admin/compliance_question/compliance/" + id).then((res) => {
      setQuestion(res.data.result);
    });
  };

  const addAnswerToAttempts = () => {
    const tempAttempts = [...attempts];
    // console.log(tempAttempts, "this is tempattmpts")
    // console.log('inside the addAnswer');
    tempAttempts.forEach((attempt) => {
      const matchingAnswer = answer.find((ans) => ans.responseId === attempt.id);
      if (matchingAnswer) {
        // console.log('match found ', matchingAnswer);
        attempt.answer = matchingAnswer;
      }
    });
    // console.log('tempattmpts', tempAttempts);
    setAttempts(tempAttempts);
  };
  const complianceName = (id) => {
    const compliance = allcompliance.find((i) => i.id === id);
    return compliance.title;
  };
  const calculateGrade = (score, totalScore) => {
    const percentage = (score / totalScore) * 100;
    // console.log(percentage, "this is percentage")

    if (percentage >= 90) {
      return "A+";
    } else if (percentage >= 80) {
      return "A";
    } else if (percentage >= 70) {
      return "B";
    } else if (percentage >= 60) {
      return "C";
    } else if (percentage >= 50) {
      return "D";
    } else {
      return "F";
    }
  };

  const getAllCompliance = () => {
    axios.get(process.env.REACT_APP_API_BASE_URL + "admin/compliance").then((response) => {
      setAllCompliance(response.data.result);
    });
  };
  const navigateToResponse = (complianceId) => {
    navigate("/dashboard/school-compliance/" + complianceId);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log(userData.id)
    navigateToResponse(complianceId);
    setShowModal(false);
  };

  useEffect(() => {
    getAllCompliance();
    getAttempts();
    // getAnswer();
  }, []);
  return (
    <>
      <div className="bg-light py-3 rounded-3">
        <div className="container">
          <h4 className=" ">All Compliances</h4>
        </div>
      </div>
      <div className="py-3">
        {/* static Modal */}
        <Modal show={showModal} onHide={() => setShowModal(!showModal)} className="rounded-0">
          <Modal.Header closeButton className="border-0">
            <Modal.Title className="">Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body className="pt-0 mt-0">
            <div>
              <div className="row">
                <div className="col-12">
                  <h6 className="text-capitalize">this compliance contain {question?.length} questions </h6>
                </div>
              </div>
            </div>
            <div className="mt-2">
              <button onClick={handleSubmit} className="btn border-dark">
                Continue
              </button>
            </div>
          </Modal.Body>
        </Modal>
        <div className="row row-cols-1 row-cols-md-2  justify-content-start">
          {allcompliance?.map((i) => {
            return (
              <div className="col mb-4">
                <Card className="w-100 rounded-4 shadow-sm shadow-hover transition" style={{ overflow: "hidden" }}>
                  <div className="img-container">
                    <img
                      style={{
                        height: "257px",
                        width: "100%",
                        objectFit: "cover",
                        objectPosition: "top",
                      }}
                      src={i.thumbnail ? i.thumbnail : "https://picsum.photos/seed/picsum/200/300"}
                      alt="alt"
                    />
                  </div>
                  <Card.Body style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                    <div>
                      <h4 className="">{i.title}</h4>
                      <button
                        onClick={() => {
                          setCompliacneId(i.id);
                          setShowModal(true);
                          getAllCategoryOfCompliance(i.id);
                          getQuestion(i.id);
                        }}
                        className="btn btn-outline-dark">
                        {checkAttempts(i.id) ? "Retake" : "Attempt"}
                      </button>
                    </div>
                  </Card.Body>
                </Card>
              </div>
            );
          })}
        </div>
        <div className="mt-4">
          <span>Showing {allcompliance?.length} compliances</span>
        </div>
      </div>
    </>
  );
};

export default ComplianceGrid;
